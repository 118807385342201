<template>
  <div>
    <b-container fluid>
      <b-row align-v="center" class="side-panel-header-sub">
        <b-col cols="1" style="text-align: center; padding: 0;">
          <pf-vehicles-icon v-if="isBikeSelected" :size="24" fillColor="#949494" />
          <pf-panel-asc-icon v-if="isAscSelected" :size="24" />
        </b-col>
        <b-col cols="10">
          <span>{{ featureTitle }}</span>
        </b-col>
        <b-col cols="1" style="text-align: right;">
          <Avatar username="J R" :size="32" backgroundColor="#000" color="#ffffff" />
        </b-col>
      </b-row>
    </b-container>
    <div class="content">
      <div id="protocol-ebike-dash" v-if="isSidePanelOpen && isBikeSelected">
        <EBikeDashboard :ebike="selectedBike"></EBikeDashboard>
        <div class="protocol-ebike-details">
          <div class="protocol-rows">
            <div class="protocol-row">
              <b-container fluid>
                <b-row>
                  <b-col cols="1">
                    <span class="protocol-row-bullet">
                      
                    </span>
                  </b-col>
                  <b-col cols="9">
                    <div class="protocol-row-info">

                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isSidePanelOpen && isAscSelected">
        <div class="spider-map">
          <img src="~@/assets/img/Panel_Protocol_Spider_Map.svg" />
        </div>
        <p
          v-pre
          class="feature feature-on"
        >{{ Indiscriminate area-based power assistance cutoff and regain }}</p>
        <p
          class="situation"
        >Cyclists on Speed-Pedelecs may be travelling at up to 45 kmph (12.5 meter per second) as they approach a space that requires them to slow down.</p>
        <p
          class="reason"
        >~ Cyclists tend not to behave like car drivers, enjoying cycling for the personal autonomy cars often fail to provide. Adaptive speed Inability of establishing accurate bicycle position, speed, and direction due to poor network latency. ~</p>
        <p
          class="purpose"
        >| Safely reduce power when coming into an area or situation that requires slow down. |</p>
        <p
          class="resolution"
        >&bullet; Define a polygonal area where power assistance to the speed pedelec is cut down, speed assistance is restricted to 25 kmph, and full power assistance is re-established after leaving the polygonal area. &bullet;</p>
        <div class="spacing"></div>
        <p>&nbsp;</p>
        <p
          v-pre
          class="feature feature-off tight"
        >{{ Directionality-based power assistance cutoff and regain }}</p>
        <p
          v-pre
          class="feature feature-off tight"
        >{{ Progressive and directional power assistance cutoff and regain }}</p>
        <p
          v-pre
          class="feature feature-off tight"
        >{{ Presence, progressive and directional power assistance cutoff and regain }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import EBikeDashboard from "@/components/bike/EBikeDashboard";
import { store, OBJECT_TYPE } from "@/store.js";

export default {
  name: "protocol",
  components: {
    Avatar,
    EBikeDashboard,
  },
  computed: {
    isSidePanelOpen() {
      return store.isSidePanelOpen;
    },
    isBikeSelected() {
      return (
        store.selectedObject != null &&
        store.selectedObject.type == OBJECT_TYPE.EBIKE
      );
    },
    isAscSelected() {
      return (
        store.selectedObject != null &&
        store.selectedObject.type == OBJECT_TYPE.ASC
      );
    },
    featureTitle() {
      if (this.isBikeSelected) {
        return store.selectedObject.displayName + " : Adaptive Power Control";
      } else if (this.isAscSelected) {
        return store.selectedObject.name;
      } else {
        return "";
      }
    },
  },
  methods: {},
  data() {
    return {
      focus: store.selectedRoute.actionMarkers.feature.container,
      selectedBike: store.selectedObject,
    };
  },
};
</script>

<style>
#protocol-ebike-dash .dashLayer {
  height: 300px !important;
}
</style>

<style scoped>
.protocol-ebike-details {
  margin-top: 280px;
}

.content {
  padding: 15px;
  background: #333333;
  color: #e2e2e2;
  font-size: 0.95rem;
  overflow: auto;
  height: calc(100% - 46px);
}

.spider-map {
  text-align: center;
  padding-bottom: 45px;
}

.content p {
  padding: 7px;
  vertical-align: 2px;
  margin-left: 40px;
}

.content p:before {
  content: "";
  width: 35px;
  height: 35px;
  display: block;
  float: left;
  margin-left: -52px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: top center;
}

.feature.tight {
  margin-bottom: 2px;
}

.situation:after,
.reason:after,
.purpose:after {
  content: "";
  width: 20%;
  display: block;
  border-bottom: 2px solid #c9c8cd;
  padding-top: 25px;
}

.protocol-row-info {
  background-color: white;
}

.knowledge-hexa:before {
  background: url("~@/assets/img/UI_KnowledgeHexa_Passive.svg");
}

.feature:before {
  background: url("~@/assets/img/Panel_Feature_Title_Icon.svg");
}

.feature.feature-off:before {
  background-color: #ffffff;
  background-image: none;
  mask-image: url("~@/assets/img/Panel_Feature_Title_Icon_Off.svg");
  mask-position: top center;
  mask-repeat: no-repeat;
  mask-size: 20px;
}

.situation:before {
  background: url("~@/assets/img/Panel_Situation_Icon.svg");
}

.reason:before {
  background: url("~@/assets/img/Panel_Reason_Icon.svg");
}

.purpose:before {
  background: url("~@/assets/img/Panel_Purpose_Icon.svg");
}

.resolution:before {
  background: url("~@/assets/img/Panel_Resolution_Icon.svg");
}

.side-panel-header-sub {
  text-align: left;
  height: 47px;
  font-weight: bold;
  font-size: 0.9rem;
}
</style>